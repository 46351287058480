/*
 * LESS COLOR DEFAULT
 */
body {
  margin: 0!important;
  padding: 0!important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #797979;
  width: 100%;
  background-color: #f1f2f7;
}
ul li {
  list-style: none;
}
img {
  max-width: 100%;
}
.dataTable img {
  max-width: none;
}
.dataTable tbody tr:hover td {
  background-color: #eeeeee;
  cursor: pointer;
}
small {
  font-size: 12px;
}
a,
a:focus {
  text-decoration: none;
  outline: none;
}
a:hover {
  text-decoration: none;
  color: #EF6F66;
}
input[type="submit"] {
  color: #FFF !important;
}
::selection {
  color: #fff;
  background: #FF6C60;
}
::-moz-selection {
  color: #fff;
  background: #FF6C60;
}
/*
 * OVERALL LAYOUT
 */
#container {
  width: 100%;
  height: 100%;
}
#header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #FFF;
  height: 55px;
  border-bottom: 1px solid #e9e9e9;
}
#header .logo {
  margin-left: 10px;
  margin-top: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #555 !important;
  float: left;
}
#header .logo img {
  height: 45px;
}
#header .fa-bars-wrapper {
  margin-top: 13px;
  font-size: 20px;
  float: left;
  color: #c8ccd7;
}
#header .account-wrapper > a {
  height: 55px;
  float: right;
  cursor: pointer;
}
#header .account-wrapper > a:hover {
  background-color: #f9f9f9;
}
#header .account-wrapper .dropdown-menu {
  margin-right: 5px;
}
#header .account-wrapper .avatar {
  width: 40px;
  margin: 8px;
  margin-left: 20px;
}
#header .account-wrapper .avatar img {
  border-radius: 50%;
}
#header .account-wrapper .name {
  display: block;
  margin: 16px;
  font-size: 16px;
}
#navi {
  position: fixed;
  top: 55px;
  background-color: #242633;
  width: 250px;
  bottom:0;
  left:0;
  overflow-y:scroll
}
#navi .sidebar-menu {
  padding-bottom: 50px;
}
#navi .sidebar-menu li {
  width: 100%;
}
#navi .sidebar-menu li > a {
  color: #6f7391;
  width: 100%;
  position: relative;
  display: block;
  padding: 15px 15px;
  transition: all 0.2s;
  font-size: 13px;
}
#navi .sidebar-menu li > a:hover {
  color: #e5e5e5;
  background-color: #191b24;
}
#navi .sidebar-menu li > a i {
  width: 15px;
  margin-right: 10px;
  text-align: center;
}
#navi .sidebar-menu li > a > .dcjq-icon {
  float: right;
}
#navi .sidebar-menu li > a.active {
  background-color: #009bc2;
  color: #FFF;
}
#navi .sidebar-menu ul.sub {
  display: none;
  background-color: #191b24;
}
#navi .sidebar-menu ul.sub.expand {
  display: block;
}
#navi .sidebar-menu ul.sub li {
  background: transparent;
}
#navi .sidebar-menu ul.sub li a {
  padding-left: 40px;
}
#main {
  position: absolute;
  height: auto;
  width: auto;
  left: 250px;
  right: 0;
  top: 55px;
  overflow: hidden;
  padding: 15px;
}
#main .title-wrapper {
  width: 100%;
  min-height: 55px;
  overflow: hidden;
  margin-bottom: 20px;
}
#main .title-wrapper .title-content {
  width: 40%;
}
#main .title-wrapper .title-content h3 {
  margin-top: 0;
}
#main .title-wrapper .title-plus {
  width: 60%;
  text-align: right;
  padding-top: 4px;
}
.spinner-wrapper {
  display: inline-block;
  padding-left: 10px;
}
.spinner-wrapper > i {
  font-size: 20px;
  margin-right: 3px;
}
.modal-black {
  color: #FFF;
}
.modal-black .spin-big-icon {
  font-size: 60px;
}
.modal-body-div {
  clear: both;
  height: auto;
  overflow: hidden;
  padding: 20px;
}
.modal-body-div button {
  margin: 20px auto 0 auto;
}
.modal-sending {
  color: #FFF;
  text-align: center;
  margin-top: 100px;
}
/*
* index.php
*/
#login {
  width: 400px;
  background: #FFF;
  margin: 5% auto 0 auto;
  border-radius: 6px;
}
#login .login-header {
  background-color: #53bee6;
  text-align: center;
  padding: 15px;
  color: #FFF;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
#login .login-body {
  padding: 20px;
}
#login .login-body input {
  margin-bottom: 10px;
  color: #797979;
  font-size: 14px;
}
/*
* BOOTSTRAP RESET CREATED BY ALEX
*/
.dropdown-menu {
  font-size: 13px;
}
.dropdown-menu li > a:hover {
  background-color: #b0b5b9;
}
.progress {
  margin-bottom: 0;
}
.form-control {
  color: #333333 !important;
}
.table-noborder tr,
.table-noborder td {
  border-top: 0 !important;
}
.panel-heading span.label {
  margin: 0 2px;
}
/*
* LAZY SETTING BY ALEX
*/
.primary {
  color: #009bc2;
}
.default {
  color: #b0b5b9;
}
.success {
  color: #78cd51;
}
.info {
  color: #53bee6;
}
.warning {
  color: #e4ba00;
}
.danger {
  color: #ec6459;
}
.wp100 {
  width: 100%;
}
.wp50 {
  width: 50%;
}
.wp25 {
  width: 25%;
}
.wf200 {
  width: 200px;
}
/* Base styles (regardless of theme) */
.bs-callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}
.bs-callout h4 {
  margin-top: 0;
}
.bs-callout p:last-child {
  margin-bottom: 0;
}
.bs-callout code,
.bs-callout .highlight {
  background-color: #fff;
}
/* Themes for different contexts */
.bs-callout-danger {
  background-color: #fcf2f2;
  border-color: #dFb5b4;
}
.bs-callout-warning {
  background-color: #fefbed;
  border-color: #f1e7bc;
}
.bs-callout-info {
  background-color: #f0f7fd;
  border-color: #d0e3f0;
}
.slides-sortable li {
  overflow: hidden;
  height: auto;
  cursor: move;
  margin: 0 !important;
  padding-top: 15px;
}
.slides-sortable li h3 {
  float: left;
  margin: 0 15px;
}
.slides-sortable li .slides-img-wrapper {
  min-height: 150px;
  margin-right: 3%;
  float: left;
}
.slides-sortable li .slides-img-wrapper img {
  height: auto;
  max-width: 245px;
}
.slides-sortable li .slides-content-wrapper {
  width: 57%;
  float: left;
  margin-bottom: 10px;
}
.slides-sortable li .slides-content-wrapper table {
  width: 100%;
}
.slides-sortable li .slides-content-wrapper table tr td {
  padding: 0px 10px 8px 0px;
  vertical-align: top;
}
label {
  margin-right: 10px;
}
label input[type="checkbox"] {
  margin-right: 3px;
}
.panel-heading .notes {
  font-size: 13px;
  color: #ff6c60;
}

[v-cloak] {
  display: none;
}
