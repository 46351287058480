a {
    color: #667fa0;
}

a:hover {
    color: #2A3542;
}

.panel {
    border: none;
    box-shadow: none;
}

.panel-heading {
    border-color: #eff2f7;
    font-size: 16px;
    font-weight: 300;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Open Sans',sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #2A3542;
}

.label {
    padding: 0.5em 0.8em;
}

.label-default {
    background-color: #a1a1a1;
}

.label-primary {
    background-color: #59ace2;
}

.label-success {
    background-color: #A9D86E;
}

.label-info {
    background-color: #8175c7;
}

.label-warning {
    background-color: #FCB322;
}

.label-danger {
    background-color: #FF6C60;
}

.label-inverse {
    background-color: #344860;
}

.text-danger {
    color: #FF6C60;
}

.text-muted {
    color: #a1a1a1;
}

.text-primary {
    color: #59ace2;
}

.text-warning {
    color: #FCB322;
}

.text-success {
    color: #A9D86E;
}

.text-info {
    color: #8175c7;
}

.modal-content {
    border: none;
    box-shadow: none;
}

.modal-header {
    border-bottom: none;
    -webkit-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    color: #fff;
    background: #00A8B3;
}

.modal-header .close {
    margin-top: 0;
}

.form-control {
    border: 1px solid #e2e2e4;
    color: #c2c2c2;
    box-shadow: none;
}

.form-control:focus,
#focusedInput {
    /*border: 1px solid #517397;*/
    border: 1px solid #53bee6;
    box-shadow: none;
}

.form-horizontal .control-label {
    text-align: left;
    font-size: 14px;
    font-weight: 300;
}

input,
textarea,
select,
button {
    outline: none!important;
}

ul {
    padding-left: 0;
}

.btn-default {
    border-color: #bec3c7;
    color: #fff;
    background-color: #bec3c7;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    border-color: #b0b5b9;
    color: #fff;
    background-color: #b0b5b9;
}

.btn-primary {
    border-color: #41cac0;
    color: #FFFFFF;
    background-color: #41cac0;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    border-color: #39b2a9;
    color: #FFFFFF;
    background-color: #39b2a9;
}

.btn-success {
    border-color: #78CD51;
    color: #FFFFFF;
    background-color: #78CD51;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
    border-color: #6dbb4a;
    color: #FFFFFF;
    background-color: #6dbb4a;
}

.btn-info {
    border-color: #58c9f3;
    color: #FFFFFF;
    background-color: #58c9f3;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
    border-color: #53BEE6;
    color: #FFFFFF;
    background-color: #53bee6;
}

.btn-warning {
    border-color: #f1c500;
    color: #FFFFFF;
    background-color: #f1c500;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
    border-color: #e4ba00;
    color: #FFFFFF;
    background-color: #e4ba00;
}

.btn-danger {
    border-color: #ff6c60;
    color: #FFFFFF;
    background-color: #ff6c60;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
    border-color: #ec6459;
    color: #FFFFFF;
    background-color: #ec6459;
}

.btn-white {
    box-shadow: none!important;
}

.btn-round {
    -webkit-border-radius: 30px;
    border-radius: 30px;
}

.btn-shadow.btn-default {
    box-shadow: 0 4px #9c9c9c;
}

.btn-shadow.btn-primary {
    box-shadow: 0 4px #29b392;
}

.btn-shadow.btn-success {
    box-shadow: 0 4px #61a642;
}

.btn-shadow.btn-info {
    box-shadow: 0 4px #1caadc;
}

.btn-shadow.btn-warning {
    box-shadow: 0 4px #cab03f;
}

.btn-shadow.btn-danger {
    box-shadow: 0 4px #d1595a;
}

.btn-group.open .dropdown-toggle,
.btn-white.active,
.btn:active,
.btn.active {
    box-shadow: none;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    text-decoration: none;
    color: #FFFFFF;
    background-color: #495d74;
}

.btn-white {
    border-color: rgba(150,160,180,0.3);
    background-color: #FFFFFF;
    background-clip: padding-box;
    box-shadow: 0 -1px 1px rgba(0,0,0,0.05) inset;
}

.breadcrumb {
    background-color: #fff;
}

.nav-tabs>li>a {
    margin-right: 1px;
}

.panel-default>.panel-heading {
    border-color: #DDDDDD;
    color: #797979;
    background-color: #FFFFFF;
}

.navbar-inverse {
    border-color: #7087A3;
    background-color: #7087A3;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus {
    background-color: #61748d;
}

.navbar-inverse .navbar-nav>li a:hover {
    color: #2A3542;
}

.navbar-inverse .navbar-nav>li>ul>li a:hover {
    color: #fff;
}

.navbar-inverse .navbar-brand {
    color: #FFFFFF;
}

.navbar-inverse .navbar-nav>li>a {
    color: #fff;
}

.navbar-inverse .navbar-nav>.dropdown>a .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}

.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
    color: #000;
}

.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
    color: #fff;
}

.nav-justified {
    width: auto!important;
}

.nav-justified li:last-child>a:hover,
.nav-justified li.active:last-child>a {
    -webkit-border-radius: 0 4px 0 0!important;
    border-radius: 0 4px 0 0!important;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
    z-index: 2;
    border-color: #00A8B3;
    color: #FFFFFF;
    background-color: #00A8B3;
}

.list-group-item-heading {
    font-weight: 300;
}

.progress {
    background: #f0f2f7;
    box-shadow: none;
}

.alert-success,
.alert-danger,
.alert-info,
.alert-warning {
    border: none;
}

.table thead>tr>th,
.table tbody>tr>th,
.table tfoot>tr>th,
.table thead>tr>td,
.table tbody>tr>td,
.table tfoot>tr>td {
    padding: 10px;
}
